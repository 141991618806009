import React, { Fragment, useEffect, useState } from 'react'

// images
import LogoImg from '../../assets/icons/logo.svg'
import Avatar1 from '../../assets/avatars/avatar1.png'
import Avatar2 from '../../assets/avatars/avatar2.png'
import Avatar3 from '../../assets/avatars/avatar3.png'
import Avatar4 from '../../assets/avatars/avatar4.png'

// custom components
import Timer from '../CountDown'
import CustomModal from '../CustomModal'

const ComingSoon = ({ onValidated, status }) => {
    const [email, setemail] = useState('')
    const [error, setError] = useState('')
    const [loading, isLoading] = useState(false)
    const [showModal, setShowModal] = useState(true)

    const closeModal = () => {
        setShowModal(false)
    }

    const emailhandler = (e) => {
        setemail(e.target.value)
        setError('')
    }

    const handleFormSubmit = () => {
        isLoading(true)
        var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (emailRegex.test(email)) {
            onValidated({ EMAIL: email })
        } else if (email === '') {
            setError('Please enter a email address')
            isLoading(false)
        } else {
            setemail('')
            setError('Please enter a valid email address')
            isLoading(false)
        }
    }

    useEffect(() => {
        if (status === 'success') {
            setShowModal(true)
            isLoading(false)
            setemail('')
        } else {
            setShowModal(false)
        }
    }, [status])

    return (
        <Fragment>
            {showModal && <CustomModal onClose={closeModal} />}
            <section className='hero-root'>
                <div className='hero-bg'>
                    <div className='hero-wrapper'>
                        <div className='hero-logo'>
                            <img src={LogoImg} alt='logo-img' />
                        </div>
                        <div className='hero-section'>
                            <span className='hero-section-tagline'>
                                We are available in
                            </span>
                            <Timer />
                            <div className='hero-content-section'>
                                {/* <span className='ready-text'>
                                    Get Ready for a&nbsp;
                                    <span className='ready-yellow-text'>
                                        life of ease
                                    </span>
                                    &nbsp;never lose a good tip again!
                                </span> */}
                                <span className='ready-text-label'>
                                    Coming Soon: Advanced Early Support for Families of Infants With Feeding Difficulties
                                </span>
                            </div>
                            <div className='email-section'>
                                <input
                                    type='email'
                                    required
                                    placeholder='Enter Your Email'
                                    className='email-input'
                                    value={email}
                                    onChange={(e) => emailhandler(e)}
                                />
                                <button
                                    className={`sign-up-btn ${error} ${
                                        isLoading
                                            ? 'loading-sign-up-btn'
                                            : 'sign-up-btn'
                                    }`}
                                    onClick={handleFormSubmit}
                                >
                                    {loading ? (
                                        <div className='sign-up-btn-loader' />
                                    ) : (
                                        'Sign Up'
                                    )}
                                </button>
                            </div>
                            {error && (
                                <span className='text-[#d82112] font-extrabold text-xl'>
                                    {error}
                                </span>
                            )}
	    		    <div>
				        <p style={{color: "white", marginTop: "50px"}}>Phone: (203) 212-8586</p>
	    		    </div>
                            {/* <div className='premiun-section'>
                                <div className='premiun-avatar-wrapper'>
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar1}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar2}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar3}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar4}
                                        alt='avatar'
                                    />
                                </div>
                                <div className='premiun-content'>
                                    <span className='premiun-text'>
                                        Premiun the first 2k subscribers for 3
                                        year free trial of our premium features!
                                    </span>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ComingSoon
